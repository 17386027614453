import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ConfirmedClickComponent } from '../../../directives/confirmed-click/confirmed-click-component';
import { ModalBodyComponent } from '../../../modal/modal-body/modal-body.component';
import { ModalContentContainerComponent } from '../../../modal/modal-content-container/modal-content-container.component';
import { ModalFooterComponent } from '../../../modal/modal-footer/modal-footer.component';
import { ModalTitleComponent } from '../../../modal/modal-title/modal-title.component';
import { ModalComponent } from '../../../modal/modal/modal.component';
import { ButtonComponent } from '../../button/button.component';

@Component({
    selector: 'app-confirmed-delete-dialog',
    template: `
        <app-modal size="sm">
            <app-modal-title>
                <h3 class="font-bold">{{ title }}</h3>
            </app-modal-title>

            <app-modal-body>
                {{ body }}
            </app-modal-body>

            <app-modal-footer>
                <div class="flex justify-end">
                    <app-button class="mr-2" (click)="modal.close.emit(false)" type="button" color="transparent">
                        {{ cancelBtn }}
                    </app-button>
                    <app-button color="red" (click)="modal.close.emit(true)" type="button">
                        {{ confirmBtn }}
                    </app-button>
                </div>
            </app-modal-footer>
        </app-modal>
    `,
    styleUrls: ['./confirmed-delete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ButtonComponent, ModalComponent, ModalTitleComponent, ModalBodyComponent, ModalFooterComponent],
})
export class ConfirmedDeleteDialogComponent implements OnInit, ConfirmedClickComponent {
    modal = inject(ModalContentContainerComponent);

    @Input() title = 'Are you sure?';
    @Input() body = 'Deleting removes this entry permanently and cannot be undone.';
    @Input() cancelBtn = 'Cancel';
    @Input() confirmBtn = 'Delete';
    @Input() params: any;
    @Input() backdrop = true;

    ngOnInit(): void {}
}
