import { afterNextRender, Directive, ElementRef, inject, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { timer } from 'rxjs';

@Directive({
    selector: '[scrollbar]',
    standalone: true,
})
export class PerfectScrollbarDirective {
    private elRef = inject<ElementRef<HTMLElement>>(ElementRef);

    perfectScrollbar!: PerfectScrollbar;

    constructor() {
        this.elRef.nativeElement.classList.add('relative');

        afterNextRender(() => {
            this.perfectScrollbar = new PerfectScrollbar(this.elRef.nativeElement);
        });
    }

    update() {
        this.perfectScrollbar.update();
    }
}
